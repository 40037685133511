'use client';

import React, { useCallback, useEffect } from 'react';
import { Divider, Flex, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@mezzoforte/forge';
import { EntryListFilterType, EntryListQuery, ListFilterOdometer, useEntryList } from '../../hooks/useEntryList';
import { EntryListFilter, EntryListFilterValues } from './EntryListFilter';
import { EntryList } from '../EntryList/EntryList';
import { FavoritesList } from '../Favorites/FavoritesList';
import { entryListItemToListEntry } from '../../util/entry';
import { Pagination } from '../Pagination/Pagination';
import { parseAsInteger, parseAsString, useQueryStates } from 'nuqs';
import { createEcommerceItem, gtmService } from '../../util/gtm-service';
import { useCategories } from '../../hooks/useCategories';
import { ListAnalytics } from '../../types/ListEntry';
import { AddSavedSearch } from './AddSavedSearch';
import { Box } from '@chakra-ui/react';
import {
  useListFilterParams,
  paramsTypeToQueryType,
  querySortToSortTab,
  queryTypeToParamsType,
} from 'hooks/useListFilterParams';

function shouldShowSavedSearchButton(query: EntryListQuery) {
  return query.filter?.searchTerm !== undefined;
}

interface EntryListFilterAndResultsProps {
  categoryId?: number;
  baseQuery?: Partial<EntryListQuery>;
  analytics: ListAnalytics;
}

export function EntryListFilterAndResults({ categoryId, baseQuery, analytics }: EntryListFilterAndResultsProps) {
  const [queryFilter, setQueryFilter] = useQueryStates({
    region: parseAsString,
    tyyppi: {
      parse: (value) => paramsTypeToQueryType[value] ?? null,
      serialize: (value) => queryTypeToParamsType[value],
    },
    met: {
      parse: (value) => value === '1',
      serialize: (value) => (value ? '1' : '0'),
    },
    paattyneet: {
      parse: (value) => value === '1',
      serialize: (value) => (value ? '1' : '0'),
    },
    merkki: parseAsString,
    vetotapa: parseAsInteger,
    km: {
      parse: (value) => (/^\d+$/.test(value) || value === 'MAX' ? (value as ListFilterOdometer) : null),
      serialize: (value) => value,
    },
    vaihteisto: parseAsInteger,
    polttoaine: parseAsInteger,
    vuosimalliMin: parseAsInteger,
    vuosimalliMax: parseAsInteger,
  });

  const {
    query: listQuery,
    setQueryPage,
    onSortChange,
    onPageChange,
  } = useListFilterParams({
    ...baseQuery?.filter,
    category: categoryId,
    region: queryFilter.region ?? undefined,
    type: queryFilter.tyyppi ?? undefined,
    soldForHighestBidder: queryFilter.met ?? undefined,
    onlyEnded: queryFilter.paattyneet ?? undefined,
    metadataManufacturer: queryFilter.merkki ?? undefined,
    metadataDrive: queryFilter.vetotapa ?? undefined,
    metadataOdometer: queryFilter.km ?? undefined,
    metadataTransmission: queryFilter.vaihteisto ?? undefined,
    metadataFuelType: queryFilter.polttoaine ?? undefined,
    metadataYearModelStart: queryFilter.vuosimalliMin ?? undefined,
    metadataYearModelEnd: queryFilter.vuosimalliMax ?? undefined,
  });

  const { status, data } = useEntryList(listQuery);
  const { status: categoriesStatus, data: categoriesData } = useCategories();

  useEffect(() => {
    if (status !== 'success') {
      return;
    }

    if (data.redirect && data.redirectUrl !== null) {
      location.href = data.redirectUrl;
    }
  }, [status, data]);

  useEffect(() => {
    if (status !== 'success' || categoriesStatus !== 'success') {
      return;
    }

    const flatCategories = (categoriesData?.categories ?? []).flatMap((c) => [c, ...Object.values(c.children)]);

    const category = flatCategories.find((c) => c.id === categoryId);
    const parentCategory = flatCategories.find((c) => c.id === category?.parentId);

    gtmService.recommendedEcommerce.viewItemList(
      analytics.listId,
      analytics.listName,
      data.entries.map((entry, index) =>
        createEcommerceItem({
          id: String(entry.id),
          name: entry.title,
          company: entry.companyName,
          brand: entry.metadata !== null && 'manufacturer' in entry.metadata ? entry.metadata.manufacturer : undefined,
          location: `${entry.zipCode} ${entry.city}`,
          priceWithoutShippingOrTax: entry.highestBid ?? entry.startPrice,
          category: entry.categoryName,
          parentCategory: parentCategory?.htmlName,
          index,
        })
      ),
      { parent_category: parentCategory?.htmlName, category: category?.htmlName }
    );
  }, [status, data, categoriesStatus, categoriesData, categoryId, analytics]);

  const onChangeFilter = useCallback(
    (values: EntryListFilterValues) => {
      const parseSelect = (v: string | undefined) => (v !== undefined && v !== '' ? v : null);
      const parseSelectAsNumber = (v: string | undefined) => (v !== undefined && v !== '' ? Number(v) : null);

      void setQueryFilter({
        region: values.region !== '' ? values.region : null,
        tyyppi: values.type !== EntryListFilterType.UsedAndNew ? values.type : null,
        met: values.soldForHighestBidder ? true : null,
        paattyneet: values.onlyEnded ? true : null,
        merkki: parseSelect(values.metadataManufacturer),
        vetotapa: parseSelectAsNumber(values.metadataDrive),
        km: parseSelect(values.metadataOdometer) as ListFilterOdometer,
        vaihteisto: parseSelectAsNumber(values.metadataTransmission),
        polttoaine: parseSelectAsNumber(values.metadataFuelType),
        vuosimalliMin: parseSelectAsNumber(values.metadataYearModelStart),
        vuosimalliMax: parseSelectAsNumber(values.metadataYearModelEnd),
      });
      void setQueryPage(1);
    },
    [setQueryFilter, setQueryPage]
  );

  const entries = status === 'success' ? data.entries.map(entryListItemToListEntry) : undefined;

  return (
    <>
      <EntryListFilter
        categoryId={categoryId}
        defaultValues={{
          soldForHighestBidder: listQuery.filter.soldForHighestBidder,
          onlyEnded: listQuery.filter.onlyEnded,
          region: listQuery.filter.region,
          type: listQuery.filter.type,
          metadataManufacturer: listQuery.filter.metadataManufacturer,
          metadataDrive: listQuery.filter.metadataDrive?.toString(),
          metadataOdometer: listQuery.filter.metadataOdometer,
          metadataTransmission: listQuery.filter.metadataTransmission?.toString(),
          metadataFuelType: listQuery.filter.metadataFuelType?.toString(),
          metadataYearModelStart: listQuery.filter.metadataYearModelStart?.toString(),
          metadataYearModelEnd: listQuery.filter.metadataYearModelEnd?.toString(),
        }}
        onSubmit={onChangeFilter}
      />

      {shouldShowSavedSearchButton(listQuery) && (
        <Box my={4}>
          <AddSavedSearch query={listQuery} />
        </Box>
      )}

      <Divider my={5} />

      <Flex
        mb={5}
        direction={{ base: 'column', md: 'row-reverse' }}
        align={{ base: 'start', md: 'center' }}
        justify="space-between"
        gap={3}
      >
        {/* TODO: Rewrite tabs in Forge, Chakra's tabs' defaultIndex does not support SSR properly. */}
        <Tabs defaultIndex={Number(querySortToSortTab[listQuery.sort] ?? 0)} onChange={onSortChange}>
          <TabList borderBottom="none">
            <Tab>Päättyvät</Tab>
            <Tab>Kiinnostavimmat</Tab>
            <Tab>Uusimmat</Tab>
          </TabList>
          <TabPanels display="none">
            <TabPanel />
            <TabPanel />
            <TabPanel />
          </TabPanels>
        </Tabs>
        <Text>
          {status === 'success' ? `${data.pages.totalItemCount} ilmoitusta, sivu ${data.pages.current}` : 'Ladataan...'}
        </Text>
      </Flex>

      <Flex>
        <EntryList flexGrow={1} entries={entries} dummyEntryCount={listQuery.limit ?? 40} analytics={analytics} />
        <FavoritesList ml={3} />
      </Flex>

      <Flex mt={5} mb={6} align="center" justify="center">
        <Pagination
          pageCount={status === 'success' ? data.pages.pageCount : 100}
          forcePage={(listQuery.page ?? 1) - 1}
          onPageChange={onPageChange}
        />
      </Flex>
    </>
  );
}
