import { useMutation } from '@tanstack/react-query';
import { useApi } from './useApi';
import { SavedSearchCreateAPIResponse, SavedSearchDeleteAPIResponse } from '../types/ApiResponse';
import { EntryListFilter, EntryListQuery, filterToQueryParam } from './useEntryList';
import { AxiosError } from 'axios';

function queryToRequestParams(query: EntryListQuery) {
  return {
    ...Object.entries(query.filter).reduce(
      (result, [name, value]) => ({
        ...result,
        [filterToQueryParam[name as keyof EntryListFilter]]: value,
      }),
      {}
    ),
    jarjestys: query.sort,
    sivu: query.page,
  };
}

export function useSavedSearch() {
  const { apiClient } = useApi();

  const addSavedSearch = useMutation<
    SavedSearchCreateAPIResponse,
    AxiosError,
    { query: EntryListQuery; email?: string }
  >({
    mutationFn: async ({ query, email }) => {
      const response = await apiClient.post<SavedSearchCreateAPIResponse>(`/api/saved-search`, {
        ...queryToRequestParams(query),
        email,
      });
      return response.data;
    },
  });

  const removeSavedSearch = useMutation<SavedSearchDeleteAPIResponse, AxiosError, { uuid: string }>({
    mutationFn: async ({ uuid }) => {
      const response = await apiClient.delete<SavedSearchDeleteAPIResponse>(`/api/saved-search/${uuid}`);
      return response.data;
    },
  });

  return {
    addSavedSearch,
    removeSavedSearch,
  };
}
